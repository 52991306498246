import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'madero-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  public title = '';
  public subtitle = '';
  public icon = '';
  public msg = '';
  public loading = false;
  public formEmail = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    private _dialogRef: MatDialogRef<ForgotPasswordComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {}

  ngOnInit(): void {
    this.title = 'Esqueci minha senha!';
    this.icon = 'password_2';
    this.subtitle = 'Insira o e-mail para redefini-la';
  }

  recovery() {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;

      this.icon = 'email';
      this.subtitle = 'O link de recuperação foi enviado para:';
      this.msg = 'opmsd***@grupomadero.com.br';
    }, 4000);
  }
}
