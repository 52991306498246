import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    title: 'Login | Madero Cashback',
    component: LoginComponent,
  },
  {
    path: 'main',
    title: 'Madero Cashback',
    loadComponent: () =>
      import('./pages/main/main.component').then((p) => p.MainComponent),
    children: [
      {
        path: 'home',
        title: 'Dashboard | Madero Cashback',
        loadComponent: () =>
          import('./pages/home/home.component').then((p) => p.HomeComponent),
      },
      {
        path: 'apprenticeFranchisees',
        loadComponent: () =>
          import(
            './pages/apprentice-and-franchisees/apprentice-and-franchisees.component'
          ).then((p) => p.ApprenticeAndFranchiseesComponent),
      },
      {
        path: 'category',
        loadComponent: () =>
          import('./pages/categories/categories.component').then(
            (p) => p.CategoriesComponent
          ),
      },
      {
        path: 'branchs',
        loadComponent: () =>
          import('./pages/branchs-control/branchs-control.component').then(
            (p) => p.BranchsControlComponent
          ),
      },
      {
        path: 'awards',
        title: 'Premiações | Madero Cashback',
        loadComponent: () =>
          import('./pages/awards/awards.component').then(
            (p) => p.AwardsComponent
          ),
      },
      {
        path: 'companies-panel',
        title: 'Empresas | Madero Cashback',
        loadComponent: () =>
          import(
            './pages/cashback-companies/cashback-companies.component'
          ).then((p) => p.CashbackCompaniesComponent),
      },
      {
        path: 'monitoring',
        title: 'Monitoramento | Madero Cashback',
        loadComponent: () =>
          import('./pages/monitoring/monitoring.component').then(
            (p) => p.MonitoringComponent
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];
