<mat-card class="c-container">
  <div class="c-main__header">
    <mat-icon class="material-symbols-rounded" color="primary">
      {{ icon }}
    </mat-icon>
    <h2>{{ title }}</h2>
    <p>{{ subtitle }}</p>
  </div>
  <div class="c-main__content">
    @if(!msg.length){
    <form [formGroup]="formEmail">
      <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input
          matInput
          formControlName="email"
          placeholder="Informe o e-mail de recuperação."
        />
      </mat-form-field>
    </form>
    <button mat-button class="c-btn c-btn--primary " (click)="recovery()">
      @if (loading) {
      <div class="c-btn-spinner">
        <mat-spinner class="c-btn--spinner"></mat-spinner>
        <span>Enviando...</span>
      </div>
      } @else {
      <span>Enviar</span>
      }
    </button>
    } @else {
    <p>{{ msg }}</p>
    }
  </div>
</mat-card>
